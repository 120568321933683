import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

export interface INgMultiselectSearchArgs {
    currentPage?: number;
    itemsPerPage?: number;
    term: string;
}

@Component({
    selector: 'ng-multiselect',
    styleUrls: ['ng-multiselect.component.less'],
    templateUrl: './ng-multiselect.component.html',
})
export class NgMultiselectComponent {
    // using any to allow for state multi-select
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() items: any[];
    @Input() placeholder = 'Select option(s)';
    @Input() selectedIds: number[] | string[] = [];
    @Input() selectAllSubset: boolean;
    @Input() virtualScroll: boolean;
    @Input() itemsPerPage = 10;
    @Input() bindValue = 'Id';
    @Input() bindLabel = 'Name';
    @Input() searchStartsWith = false;
    @Output('scrollToEnd') scrollToEnd = new EventEmitter<INgMultiselectSearchArgs>();
    @Output('search') search = new EventEmitter<INgMultiselectSearchArgs>();
    @Output('selectedIdsChange') changeEmitter = new EventEmitter();

    clearSearchOnAdd = true;
    currentPage = 1;
    query: string;

    selectAll = false;
    searchSet = [];

    constructor(private cdr: ChangeDetectorRef) {}

    onSearch(event: { term: string; items: unknown[] }): void {
        this.searchSet = event.items;
        this.query = event.term;
        this.currentPage = 1;
        const searchArgs: INgMultiselectSearchArgs = this.getSearchArgs();
        this.search.emit(searchArgs);
    }

    loadMore(): void {
        this.currentPage++;
        const searchArgs: INgMultiselectSearchArgs = this.getSearchArgs();
        this.scrollToEnd.emit(searchArgs);
    }

    onClear(): void {
        this.clearSearch();
    }

    onRemove(): void {
        if (this.selectAll) {
            this.selectAll = false;
        }
    }

    onAdd(): void {
        if (this.query && this.clearSearchOnAdd) {
            this.clearSearch();
        }
    }

    clearSearch(): void {
        this.currentPage = 1;
        this.query = undefined;
        this.searchSet = [];
        this.selectAll = false;
        const searchArgs: INgMultiselectSearchArgs = this.getSearchArgs();
        this.search.emit(searchArgs);
        this.cdr.detectChanges();
    }

    getSearchArgs(): INgMultiselectSearchArgs {
        return {
            currentPage: this.virtualScroll ? this.currentPage : undefined,
            itemsPerPage: this.virtualScroll ? this.itemsPerPage : undefined,
            term: this.query,
        };
    }

    selectAllItems(): void {
        if (this.selectAll) {
            this.selectedIds = this.searchSet.length ? this.searchSet.map((s) => s[this.bindValue]) : this.items.map((s) => s[this.bindValue]);
            this.onAdd();
        } else {
            this.selectedIds = [];
        }
        this.changeEmitter.emit(this.selectedIds);
    }

    searchFunction(): (term:string, item:unknown) => boolean {
        if (this.searchStartsWith) {
            return (term: string, item: unknown) => item[this.bindLabel].toLowerCase().startsWith(term.toLowerCase());
        }
        return (term: string, item: unknown) => item[this.bindLabel].toLowerCase().includes(term.toLowerCase());
    }
}
