export enum ClaimTypes {
    AdminSettings = 1,
    CustomerSettings = 2,
    ProductSettings = 3,
    UserSettings = 4,
    Audit = 5,
    AccessRequests = 6,
    Orders = 7,
    ManageOrders = 8,
    SendAllReadyToShips = 9,
    Invoices = 10,
    Loads = 11,
    Customers = 12,
    Inventory = 13,
    ManageInventory = 14,
    InventoryChangeReason = 15,
    Plants = 16,
    Products = 17,
    Production = 18,
    Propagation = 19,
    Procurement = 20,
    Receipts = 21,
    PottingSheets = 22,
    Reports = 23,
    SalesDataset = 24,
    FinancialReports = 25,
    ViewAllCustomers = 26,
    ViewAllOrders = 27,
    ViewAllInvoices = 28,
    ViewAllFinancialReports = 29,
    CustomerPortal = 30,
    SavedSearches = 31,
    SalesReports = 32,
    CustomerSalesSummary = 33,
    ViewAllCustomerSalesSummary = 34,
    CustomerSalesDetails = 35,
    ViewAllCustomerSalesDetails = 36,
    SalesComparison = 37,
    ViewAllSalesComparison = 38,
    SettingsLoads = 39,
    SettingsCustomers = 40,
    SettingsInventory = 41,
    SettingsPlants = 42,
    SettingsProducts = 43,
    InventoryDataset = 44,
    CustomerDataset = 45,
    ShipToContactDataset = 46,
    ProductCategories = 47,
    InvoiceDataset = 48,
    CreditMemoDataset = 49,
    ManageOrderAllocations = 50,
}
